import React, {useEffect, useState} from 'react';
// import {useMediaQuery} from 'react-responsive';
import Layout from '../../components/layout';
// import Input from '../../components/shared-components/input';
// import Button from '../../components/shared-components/button';
// import {isValidEmail} from '../../helpers';
// import videoImg from '../../assets/intro-to-startengine.png';
import './styles.scss';
import { navigate } from 'gatsby';
// import {getRawCookie} from '../../components/layout/header';

const StartEngine = () => {
  // const [playVideo, setPlayVideo] = useState(true);
  // const [email, setEmail] = useState('');
  // const [firstName, setFirstName] = useState('');
  // const [lastName, setLastName] = useState('');
  // const [error, setError] = useState('');
  // const [success, setSuccess] = useState(false);
  // const [requestLoading, setRequestLoading] = useState(false);
  // const [ref, setRef] = useState('');

  // const isMobileSm = useMediaQuery({maxWidth: 585});

  // const handleSubmit = () => {
  //   setError('');
  //   setSuccess(false);
  //   const cleanEmail = email.trim();
  //   const cleanName = firstName.trim();
  //   const cleanLastName = lastName.trim();
  //   if (!cleanEmail || !isValidEmail(cleanEmail) || !cleanName || !cleanLastName) {
  //     setError(`Please enter valid ${!cleanName ? 'first name' : !cleanLastName ? 'last name' : 'email address'}.`);
  //     return;
  //   } else {
  //     getExistingResult();
  //   }
  // };
  // const analytics = () => {
  //   if (window.location.href.includes('hexact.io')) {
  //     //@ts-ignore
  //     window.dataLayer.push({
  //       event: `startengine-subscription`,
  //       startengineSubscriptionId: `StartEngine_subscription_${new Date().toISOString()}`,
  //     });
  //     //@ts-ignore
  //     window.dataLayer.push({
  //       event: `startengine-subscription`,
  //       ecommerce: {
  //         subscription: {
  //           actionField: {
  //             id: `StartEngine_subscription_${new Date().toISOString()}`,
  //           },
  //         },
  //       },
  //     });
  //   }
  // };
  // const getExistingResult = async () => {
  //   setRequestLoading(true);
  //   try {
  //     const result = await fetch(`https://api.hexometer.com/v2/app/startengine/inform`, {
  //       method: 'POST',
  //       headers: {
  //         Accept: 'application/json',
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({
  //         firstname: firstName,
  //         lastname: lastName,
  //         email: email.trim(),
  //         referrer: ref || getRawCookie('document_referrer') || '',
  //       }),
  //     });
  //     await result
  //       .json()
  //       .then(({status, message}) => {
  //         if (status === 'success') {
  //           setSuccess(true);
  //           setEmail('');
  //           setLastName('');
  //           setFirstName('');
  //           setError('');
  //           analytics();
  //         } else {
  //           if (message === 'Duplicate email') {
  //             setError('Already subscribed.');
  //           } else {
  //             setError('Something went wrong.');
  //           }
  //         }

  //         setRequestLoading(false);
  //       })
  //       .catch(() => {
  //         setRequestLoading(false);
  //         setError('Something went wrong.');
  //       });
  //   } catch (e) {
  //     setRequestLoading(false);
  //     setError('Something went wrong.');
  //   }
  // };
  // const handleKeyUp = (e: any) => {
  //   if (e.key === 'Enter') handleSubmit();
  // };

  // const handleChange = (e: any) => {
  //   setError('');
  //   setEmail(e.target.value);
  // };

  // const handleLastNameChange = (e: any) => {
  //   setError('');
  //   setLastName(e.target.value);
  // };

  // const handleFirstNameChange = (e: any) => {
  //   setError('');
  //   setFirstName(e.target.value);
  // };

  // useEffect(() => {
  //   const search = global && global.window && global.window.location && global.window.location.search;
  //   const obj = {
  //     hexomatic: 'https://hexomatic.com',
  //     hexometer: 'https://hexometer.com',
  //     hexowatch: 'https://hexowatch.com',
  //     hexofy: 'https://hexofy.com',
  //     hexospark: 'https://hexospark.com',
  //   };
  //   const localRef = search.includes('utm_source=hexospark')
  //     ? obj['hexospark']
  //     : search.includes('utm_source=hexofy')
  //     ? obj['hexofy']
  //     : search.includes('utm_source=hexomatic')
  //     ? obj['hexomatic']
  //     : search.includes('utm_source=hexometer')
  //     ? obj['hexometer']
  //     : search.includes('utm_source=hexowatch')
  //     ? obj['hexowatch']
  //     : '';
  //   setRef(localRef || '');
  // }, []);

  useEffect(() => {
    navigate('/for-investors/');
  }, []);

  return (
    <Layout startenginePage>
      {/* <div className="section-padding">
        <iframe
          src="https://player.vimeo.com/video/810532016?h=a7dad5749f&amp;badge=0&amp;autoplay=1&amp;muted=1&amp;player_id=0&amp;app_id=58479"
          width={isMobileSm ? '350' : '600'}
          height={isMobileSm ? '196' : '338'}
          allow="autoplay; fullscreen; picture-in-picture"
          frameBorder={0}
          title="Intro to StartEngine"
          style={{maxWidth: '100%'}}
        />
        <div style={{maxWidth: '600px', margin: '30px auto'}}>
          <div style={{padding: '15px', boxShadow: '0 20px 30px 0 rgba(15, 0, 57, 0.1)', borderRadius: '6px'}}>
            <div className="row m-0">
              <div className="col-12 col-lg-6 p-1">
                <Input
                  placeholder="First name"
                  onChange={handleFirstNameChange}
                  value={firstName}
                  name="firstname"
                  id="firstname"
                  error={error.includes('valid first name') ? error : ''}
                  onKeyUp={handleKeyUp}
                  maxLength={255}
                />
              </div>
              <div className="col-12 col-lg-6 p-1">
                <Input
                  placeholder="Last name"
                  onChange={handleLastNameChange}
                  value={lastName}
                  name="lastName"
                  id="lastName"
                  error={error.includes('valid last name') ? error : ''}
                  onKeyUp={handleKeyUp}
                  maxLength={255}
                />
              </div>
            </div>
            <div className="p-1">
              <Input
                placeholder="Email"
                onChange={handleChange}
                value={email}
                name="email"
                id="email"
                error={error.includes('valid email') ? error : ''}
                onKeyUp={handleKeyUp}
                maxLength={320}
              />
            </div>
            <Button
              btnText="Get investor pack"
              loading={requestLoading}
              disabled={requestLoading}
              onClick={handleSubmit}
              fullWidth
              success
              className="mt-2"
            />
            {error && <p className="error-message text-center">{error}</p>}
            {success && <p className="success-message text-center">Thank you, your welcome pack is on its way.</p>}
          </div>
        </div>
      </div> */}
    </Layout>
  );
};

export default StartEngine;
